import { Typography, useTheme, Box, SxProps, Theme } from '@mui/material';
import { navigate } from 'gatsby';
import asterisk from './imgs/Shapes/Asterisk.png';
import blob from './imgs/Shapes/Blob.png';
import dot from './imgs/Shapes/Dot.png';
import hexagon from './imgs/Shapes/Hexagon.png';
import spiral from './imgs/Shapes/Spiral.png';
import square from './imgs/Shapes/Square.png';
import { FlatButton } from 'components/FlatButton';
import { ColourBlock, DropShadowCard, IconComment } from '@vega/common-react';
import arrow from './imgs/arrow.png';
// import imgWhoFor from './imgs/who-gmci-for.png';
// import imgCheckInPreview from './imgs/check-in-preview.png';
// import imgFeedbackPreview from './imgs/feedback-recommendation-preview.png';
import { Collaborators } from 'components/Collaborators';
import { i18next } from '@vega/common';

const t = i18next.t;
export function Hero() {
  const theme = useTheme();

  const participateButton = (
    <FlatButton
      sx={{ marginY: theme.spacing(4), fontSize: 18 }}
      onClick={() => {
        navigate('/participate');
      }}>
      {t('landing-page.start-button.text')}
    </FlatButton>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}>
      <Box sx={{ maxWidth: '51rem', my: theme.spacing(7), mx: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ position: 'relative', marginBottom: { xs: 2, md: theme.spacing(4) } }}>
            <img alt="" style={{ position: 'absolute' }} src={spiral} width={15} height={15} />
            <img
              alt=""
              style={{ position: 'absolute', top: '40%', left: 10, transform: 'rotate(5deg)' }}
              src={blob}
              width={25}
              height={25}
            />
            <img alt="" style={{ position: 'absolute', left: '10%', top: 10 }} src={dot} width={25} height={25} />
            <img alt="" style={{ position: 'absolute', left: '40%' }} src={spiral} width={25} height={25} />
            <img alt="" style={{ position: 'absolute', right: '40%', top: 25 }} src={asterisk} width={25} height={25} />
            <img alt="" style={{ position: 'absolute', right: '10%', top: 23 }} src={square} width={15} height={15} />
            <img
              alt=""
              style={{ position: 'absolute', right: 0, transform: 'rotate(5deg)' }}
              src={hexagon}
              width={18}
              height={18}
            />
          </Box>
          <Box
            sx={{
              paddingTop: theme.spacing(7),
            }}>
            <Typography
              variant="h3"
              sx={{
                mb: 2,
                fontWeight: 800,
                fontSize: { xs: '2.4rem', sm: '3rem', md: '3.75rem' },
              }}>
              {t('landing-page.title')}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <H3>{t('landing-page.subtitle')}</H3>
          <P>{t('landing-page.description')}</P>
          {participateButton}
          <IconComment img={arrow} sx={{ textAlign: 'left', alignSelf: 'end' }}>
            {t('landing-page.start-button.description')}
          </IconComment>
        </Box>
      </Box>
      <ColourBlock
        backgroundColor="background.default"
        sx={{ py: theme.spacing(6), display: 'flex', flexDirection: 'column', alignItems: 'center', px: 3 }}>
        <H2
          sx={{
            py: 2,
          }}>
          {t('landing-page.section-1.title')}
        </H2>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mt: { xs: 2, md: 6 },
            mb: 6,
            maxWidth: '51rem',
            justifyContent: 'center',
            gap: { xs: '1rem', md: '2rem' },
            minHeight: '25rem',
            textAlign: 'start',
          }}>
          <DropShadowCard sx={{ width: { xs: '100%', md: '15em' }, p: theme.spacing(3) }}>
            <img alt="" src={blob} width={40} height={40} style={{ display: 'block' }} />
            <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-1.benefit-1.title')}</P>
            <P>{t('landing-page.section-1.benefit-1.description')}</P>
          </DropShadowCard>
          <DropShadowCard sx={{ width: { xs: '100%', md: '15em' }, p: theme.spacing(3) }}>
            <img alt="" src={spiral} width={40} height={40} style={{ display: 'block' }} />
            <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-1.benefit-2.title')}</P>
            <P>{t('landing-page.section-1.benefit-2.description')}</P>
          </DropShadowCard>
          <DropShadowCard sx={{ width: { xs: '100%', md: '15em' }, p: theme.spacing(3) }}>
            <img alt="" src={dot} width={40} height={40} style={{ display: 'block' }} />
            <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-1.benefit-3.title')}</P>
            <P>{t('landing-page.section-1.benefit-3.description')}</P>
          </DropShadowCard>
        </Box>
        <P sx={{ textAlign: 'center', maxWidth: '51rem', mt: 4, display: 'flex', flexDirection: 'column' }}>
          {t('landing-page.section-1.disclaimer')}
          <IconComment img={arrow} sx={{ mt: 1, textAlign: 'left', alignSelf: 'end' }}>
            {t('landing-page.section-1.reminder')}
          </IconComment>
        </P>
      </ColourBlock>
      <Box sx={{ my: 14, maxWidth: '51rem', mx: 3 }}>
        <H3 sx={{ mb: 5 }}>{t('landing-page.contributors-description')}</H3>
        <Collaborators />
      </Box>
      <H2 sx={{ pt: 14, mb: { xs: 6 } }}>{t('landing-page.section-2.title')}</H2>
      <Box
        sx={{
          display: 'flex',
          mb: 10,
          gap: { xs: '2rem', md: '8rem' },
          flexWrap: 'wrap',
          justifyContent: 'center',
          mx: 3,
        }}>
        <Box sx={{ maxWidth: '12rem' }}>
          <img
            alt=""
            src={asterisk}
            width={40}
            height={40}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-2.benefit-1.title')}</P>
          <P>{t('landing-page.section-2.benefit-1.description')}</P>
        </Box>
        <Box sx={{ maxWidth: '12rem' }}>
          <img
            alt=""
            src={spiral}
            width={40}
            height={40}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-2.benefit-2.title')}</P>
          <P>{t('landing-page.section-2.benefit-2.description')}</P>
        </Box>
        <Box sx={{ maxWidth: '12rem' }}>
          <img
            alt=""
            src={dot}
            width={40}
            height={40}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-2.benefit-3.title')}</P>
          <P>{t('landing-page.section-2.benefit-3.description')}</P>
        </Box>
        <Box sx={{ maxWidth: '12rem' }}>
          <img
            alt=""
            src={hexagon}
            width={40}
            height={40}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-2.benefit-4.title')}</P>
          <P>{t('landing-page.section-2.benefit-4.description')}</P>
        </Box>
        <Box sx={{ maxWidth: '12rem' }}>
          <img
            alt=""
            src={square}
            width={40}
            height={40}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <P sx={{ fontWeight: 'bold', my: 2 }}>{t('landing-page.section-2.benefit-5.title')}</P>
          <P>{t('landing-page.section-2.benefit-5.description')}</P>
        </Box>
      </Box>
      {/* <ColourBlock
        backgroundColor="violet.main"
        sx={{
          color: 'violet.contrastText',
          textAlign: 'left',
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            maxWidth: '60rem',
          }}>
          <Box sx={{ maxWidth: { md: '30rem' }, pt: 4, pb: { xs: 0, md: 4 }, my: { xs: 0, md: 2 } }}>
            <H3 sx={{ textAlign: { xs: 'center', md: 'left' } }}>Self-directed check-in system</H3>
            <P>
              The GMCI-P is a self-directed check-in system which allows you to identify if your child is at risk of a
              social, emotional and/or behavioural (SEB) problem by answering a set of questions about your child's SEB
              wellbeing and development. GMCI-P provides automated feedback based on your answers and recommendations
              for online evidence-based programs if needed.
            </P>
            <FlatButton
              color="info"
              sx={{ paddingX: 5, mt: 4, fontSize: 18 }}
              onClick={() => {
                navigate('/participate');
              }}>
              Learn more
            </FlatButton>
          </Box>
          <img
            src={imgCheckInPreview}
            alt="Preview of check-in screens."
            style={{ maxWidth: 'min(30rem, 100%)', maxHeight: '30rem' }}
          />
        </Box>
      </ColourBlock>
      <ColourBlock
        backgroundColor="carnation.main"
        sx={{
          color: 'carnation.contrastText',
          textAlign: 'left',
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: '100%',
            maxWidth: '60rem',
            py: { md: 5 },
          }}>
          <img
            src={imgFeedbackPreview}
            width="auto"
            height="auto"
            alt="Preview of feedback screens."
            style={{ maxHeight: '30rem', maxWidth: '100%', flex: 1, objectFit: 'contain' }}
          />
          <Box sx={{ flex: 1, textAlign: 'start', display: 'flex', flexDirection: 'column', pt: { xs: 5, md: 0 } }}>
            <H3 sx={{ textAlign: { xs: 'center', md: 'left' } }}>Personalized feedback and recommendations</H3>
            <P>
              Your journey starts with questions about your child's SEB and development. This allows us to then give you
              personalised feedback about your child's wellbeing. This feedback can be downloaded and shared with other
              professionals or caregivers in a child's life. Based on this feedback, we will recommend online
              evidence-based programs for child wellbeing and development, where needed.
            </P>
            <IconComment img={arrow} sx={{ width: { md: '18rem' }, alignSelf: 'end' }}>
              "Evidence-based" means that the strategies in the program have been tested and found to work.
            </IconComment>
          </Box>
        </Box>
      </ColourBlock> */}
      <Box my={4} sx={{ maxWidth: '51rem', p: theme.spacing(4) }}>
        <H3>{t('landing-page.footer-section.title')}</H3>
        <P>{t('landing-page.footer-section.description', { email: 'gmci.c@monash.edu' })}</P>
        {participateButton}
      </Box>
    </Box>
  );
}

const H2 = (props: { children: React.ReactNode; sx?: SxProps<Theme> }) => (
  <Typography
    fontSize="2.5rem"
    paragraph
    variant="h2"
    fontWeight="bold"
    sx={[
      { fontSize: { xs: '2rem', md: '2.5rem' }, mb: { xs: 0, md: 2 } },
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
    ]}>
    {props.children}
  </Typography>
);

const H3 = (props: { children: React.ReactNode; sx?: SxProps<Theme> }) => (
  <Typography
    paragraph
    variant="h3"
    fontWeight="bold"
    sx={[{ fontSize: { xs: '1.5rem', md: '1.875rem' } }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}>
    {props.children}
  </Typography>
);

const P = (props: { children: React.ReactNode; sx?: SxProps<Theme> }) => (
  <Typography component="div" fontSize="1.125rem" paragraph sx={props.sx}>
    {props.children}
  </Typography>
);
