import { SitePage } from 'app/layout';
import { Hero } from 'app/landing';

export default function Index() {
  return (
    <SitePage>
      <Hero />
    </SitePage>
  );
}
